// src/components/SearchBar.js
import React from 'react';
import './SearchBar.css';
import searchIcon from '../Assets/search-button.png';
import { Link } from 'react-router-dom'
import  { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function SearchBar() {
  const [query, setQuery] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  // Update state on input change
  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  // Trigger search on Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  // Function to navigate to the search page
  const search = () => {
    navigate(`/search?q=${query}`); // Navigate without reloading the page
  };

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search Our Help Database"
        className="search-bar"
        value={query}
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Listen for key presses
      />
      <button className="search-button" onClick={search}>
        <img src={searchIcon} alt="Search Icon" />
      </button>
    </div>
  );
}
export default SearchBar;
