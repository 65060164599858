// Sidebar.js
import React, { useState } from 'react';
import './sidebar.css';
import logo from '../Assets/logo.png'

function Sidebar({ isOpen, onClose }) {
  const handleOverlayClick = () => {
    if (isOpen) {
      onClose();
    }
  };

  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
 
  const toggleSidebar = () => {
    console.log("close")
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <><div>
        <div className="sidebar-content overlay" 
          style={{display: (isOpen )? 'block' : 'none' }} >
             <div className='close-button'  onClick={handleOverlayClick}> X</div>
          <div className="sidebar-top" onClick={handleOverlayClick}>
           
            <div className="tm-logo">
                <img src={logo} alt="Custom Logo" />
              <div className="header-info">
                <p className="company-name">Table Mate</p>
                <p className="site-title">Documentation</p>
              </div>
            </div>
          </div>
          <div className="sidebar-bottom">
            <h3 className="sidebar-heading">Frequently Searched</h3>
            <ul className="sidebar-links">
              <li>How to search for nearby restaurants</li>
              <li>How to create an account</li>
              <li>How to place an order</li>
              <li>How to ask for the bill</li>
              <li>How to split the bill</li>
              <li>How to access account history</li>
              <li>How to log out</li>
            </ul>
          </div>
        </div>
        </div>
    </>
  );
}

export default Sidebar;
