import React from 'react';
import './Footer.css';
import './Details.css';
import { Link } from 'react-router-dom';
import logo from '../Assets/logo.png'
import linkedInLogo from '../Assets/LI-Logo.png'
function Footer() {

  return (
    <footer>
    <div className="details-container">
      
      <div className="additional-links">
        <a href="#" className="additional-link">Require Additional Support?</a>
        <br /> {/* Add a line break to place "Contact Us" below */}
        <a href="/contact" className="additional-link">Contact Us</a>
      </div>
    </div>
    <div className='footer-main'>
      <div className='footer-nav'>
      <img className='tm-logo-footer' src={logo} alt="TM Logo" />
      <div className='footer-contact'>
        <div>Info@tablemate.net</div>
        <div>+267 76020625</div>
        <div>PO Box 1697 Abg,</div>
        <div>Gaborone, Botswana</div>
      </div>
      <div className='footer-navigation-links'>
      <Link to="https://tablemate.net">Visit Table Mate Website</Link>
      </div>

      </div>
      <div className='footer-copyright'>
        <Link to="https://www.linkedin.com/company/75034167/"><img className='linkedin-logo-footer' src={linkedInLogo} alt="LinkedIn Logo" /></Link>
        <div>© Table Mate {(new Date().getFullYear())}</div>
      </div>
      
    </div>
    </footer>
  );
}

export default Footer;
