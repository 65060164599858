import React from 'react';
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import backIcon from '../Assets/arrow-back.png'
import './DocArticle.css';
import { useEffect, useState,useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function DocArticle ({code,title}) {
  const location = useLocation();
  const { category, id } = useParams(); // assuming your route has :category/:project
    console.log("Page Route: " + category + " /  "+id)
    const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  function nav_back (){

    if (src == "search")
   navigate(-1)
    //navigate(`/search?q=${query}`); // Navigate without reloading the page
    else
      navigate("/"+category+"?s="+s)
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const s =searchParams.get("s")

  const src =searchParams.get("src")

  const divRef = useRef(null);
  const scrollToTop = () => {
    // Accessing the current property of the ref to get the DOM node
    const divElement = divRef.current;
    // Scroll the div to the top
    if (divElement) {
      divElement.scrollTop = 0;
    }
  };

//download  categories
var categoryTitles = {}
const [content_data, setData] = useState(null);

useEffect(() => {

  fetch('https://api.tablemate.net/fetchDocArticles.php?category='+category+"&id="+id+"&s="+s)
    .then(response => response.json())
    .then(content_data =>{ setData(content_data)
      console.log("Results: "+JSON.stringify(content_data))
      try{
        content_data.forEach(element => {
          
     
          });  
        }catch (ex){
          console.log("Error: "+ex)
        } 
        console.log(JSON.stringify(content_data))
        renderArticle(content_data)
    })
    .catch(error => console.error('Error fetching data:', error));
}, [location]);

const [articleTitle, setArticleTitle] = useState("Loading...");
const [docArticle, setProjectCategories] = useState(false);
const renderArticle =  (content_data) => {
  console.log("Reacheed " )
  var page =""
  if(content_data[0].description != null)
    page = content_data[0].description.replace(new RegExp("/sites/default/files", "g"), "https://admin.tablemate.net/sites/default/files")
  var anArticle =content_data[0]
  setArticleTitle(anArticle.title)
  console.log("Created" + anArticle.created)
  const listArticleItems = 
      <div className="doc-article" >
        
        <div className="doc-article-body" dangerouslySetInnerHTML={{ __html: page }} >
        </div>
        <div > {content_data[0].description  != "" ? page : "<div>Sorry, No Content</div>" }</div >

        <hr></hr>
        <div className="doc-article-application">
          <strong>Application: </strong>{anArticle.application  != null ?  <Link className='details-link' to={`/search?q=${encodeURIComponent(anArticle.application.trim())}`}>{anArticle.application}</Link> : "Sorry, No Content"}
        </div>
        <div className="doc-article-tags">
        <hr></hr>
        <strong>Tags: </strong>
        {anArticle.tags != null &&
        (anArticle.tags.split(',').map((tag, index) => (
            <React.Fragment key={index}>
              {index > 0 && ', '} {/* Add a comma before tags except the first one */}
              <Link className='details-link' to={`/search?q=${encodeURIComponent(tag.trim())}`}>{tag.trim()}</Link>
            </React.Fragment>
          ))
        )
      }
        </div>
        <br></br>
        <div className='doc-article-date'>
          <div><strong>Created: </strong>{TimestampToDateComponent(anArticle.created)}</div>
          <div><strong>Last Updated:</strong> {TimestampToDateComponent(anArticle.changed)}</div>
        </div>
      </div>
      {try {document.getElementsByClassName("doc-article")[0].childNodes[1].remove()}catch(e){}}
    setProjectCategories(listArticleItems)
  return (
    
    listArticleItems
  );
return null;
};

function TimestampToDateComponent(timestamp ) {
  // Convert the Unix timestamp to milliseconds (Unix timestamp is in seconds)
  const date = new Date(timestamp * 1000);

  // Format the date into a readable string
  // You can adjust the options to suit your formatting preferences
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: 'long', // "Monday"
    year: 'numeric', // "2023"
    month: 'long', // "July"
    day: 'numeric', // "20"
  });

  return (
    formattedDate
  );
}
function capitalizeFirstLetterOfEveryWord(str) {
  return str.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}

  return(
    <div className="RetaurantCheckin">
      <div className="details-container-page">
      <div className='details-container-page-title'>
        <button className="back-button" onClick={nav_back}>
          <img src={backIcon} alt="Back buutton" />
        </button>
          <h3 className='detailTitle'>{articleTitle}</h3>
        </div>
       
        {docArticle}
       
      </div>
    </div>
  );
}

export default DocArticle