// App.js
import React, { useState } from 'react';
import './App.css';
import './global.css'
import Navbar from './components/navbar';
import SearchBar from './components/SearchBar';
import Details from './components/Details';
import Home from './components/Home';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DocCategory from './components/DocCategory'
import DocArticle from './components/DocArticle'
import Contact from './components/Contact'

import SearchResults from './components/SearchResults';
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <Router>
    <div className="App">
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <SearchBar />
      <Details />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path=":category" element={<DocCategory />} />
          <Route path=":category/kb/:id" element={<DocArticle />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
