import React from 'react';
import './Details.css';
import { Link } from 'react-router-dom';

function Details() {
  return (
    <div className="details-container">
      <h2 className="details-heading">Popular topics</h2>
      <ul className="details-list">
        <li>
          <Link className="details-link" to = "account-settings?s=f">
          <span >Account Settings</span>
          </Link>
        </li>
        <li className="separator">|</li>
        <li>
        <Link className="details-link" to = "restaurant-check-in?s=f">
          <span className="details-link">Restaurant Check-In</span>
          </Link>
        </li>
        <li className="separator">|</li>
        <li>
        <Link className="details-link" to = "dining-session?s=f">
          <span className="details-link">Dining Session</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Details;
