import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'

import backIcon from '../Assets/arrow-back.png'
import './SearchResults.css';
function SearchResults () {
  const location = useLocation();
  const { category, project } = useParams(); // assuming your route has :category/:project
    console.log("Page Search Query: " + query)
  function back (){

  }
  
  var query = ""
  // Use URLSearchParams to parse the query parameters
  const queryParams = new URLSearchParams(location.search);
     query = queryParams.get('q'); // "q" is the name of the query parameter
  var page =  queryParams.get('p'); // "q" is the name of the query parameter

     //download  categories
var articles = []
const [content_data, setData] = useState(null);
const [totalPages, setTotalPages] = useState("");
var [currentPage, setCurrentPage] = useState(1);
var [resultsCount, setTotalResults] = useState(0);



useEffect(() => {
  console.log('https://api.tablemate.net/search.php?q='+query+"&p="+page == null ? 1 : page)
  page  = page == null ? "1" : page
  fetch('https://api.tablemate.net/search.php?q='+query+"&p="+page)
    .then(response => response.json())
    .then(content_data =>{ setData(content_data)
      console.log("Results: "+JSON.stringify(content_data))
      try{
        var count =0
        content_data.forEach(element => {
          
          if (element.status=="1"){
            count += 1;
            articles[element.nid] = element
          }
          });  
        }catch (ex){
          console.log("Error: "+ex)
        } 
        console.log(count+ " - elements")
       
        console.log(JSON.stringify(articles))
        renderCategories(articles)
        
        setCurrentPage(page)
        setTotalPages(content_data[0].pages);
        setTotalResults(content_data[0].count);
    })
    .catch(error => console.error('Error fetching data:', error));
}, [location]);

const [docArticles, setdocArticles] = useState(false);
const renderCategories =  (list) => {
  const listDocArticleItems = Object.keys(list).map((anArticle, index) => (
    <li className='details-link'>
      <Link 
        to={"/"+list[anArticle]["category_code"] +`/kb/${list[anArticle]["nid"]}?s=${list[anArticle]["application"] === "Front End Mobile Application" ? "f" : list[anArticle]["application"] === "Back End Mobile Application" ? "b" : 's'}&src=search`} 
        state={{ category: category }}>
          <span>{list[anArticle]["title"]}</span>
      </Link>
      <div className='search-summary'>{list[anArticle]["summary"]}</div>
      
      <div className='search-application'>Application: <i><Link className='details-link' to={`/search?q=${encodeURIComponent(list[anArticle]["application"])}`}>{list[anArticle]["application"]}</Link></i></div>
      {list[anArticle]["tags"] && (
        <div className='search-tags'>Tags: {
          list[anArticle]["tags"].split(',').map((tag, index) => (
            <React.Fragment key={index}>
            {index > 0 && ', '} {/* Add a comma before tags except the first one */}
            <Link to={`/search?q=${encodeURIComponent(tag)}`}>{tag}</Link>
          </React.Fragment>
          
          ))
        }</div>
      )}
      <hr></hr>
    </li>
  ));
  
    setdocArticles(listDocArticleItems)
  return (
    listDocArticleItems
  );
return null;
};


function capitalizeFirstLetterOfEveryWord(str) {
  return str.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}

const handlePageChange = (newPage) => {
 // setCurrentPage(newPage);
 window.location.href = "search?q="+query+"&p="+newPage
};



const Pagination = ({ currentPage, totalPages, onPageChange }) => {
const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

return (
  <div>
    {pages.map(page => (
      <button
        key={page}
        disabled={currentPage === page}
        onClick={() => onPageChange(page)}
      >
        {page}
      </button>
    ))}
  </div>
);
};


//SEARCH RESULTS DISPLAY
  return(
    <div className="RetaurantCheckin">
      <div className="details-container-page">
        <div className='details-container-page-title'>
          <Link to="/">
        <button className="back-button" onClick={back}>
          <img src={backIcon} alt="Back buutton" />
        </button>
        </Link>
          <h3 className='detailTitle'>{resultsCount} Search Results For: {query}</h3>
          
        </div>
        <ul className="doc-article-list">
      {/*{data.map((item, index) => (
        <li key={index}>
           Display your data here 
          {item.title}
        </li>
      ))}*/}
      {docArticles}
    </ul>
    <div>Page {currentPage} of {totalPages} </div>
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
    />
       
      </div>
    </div>
  );
}

export default SearchResults