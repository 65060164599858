import React from 'react';
import './Details.css';
import { Link } from 'react-router-dom';
import { useEffect, useState,useRef } from 'react';
function Home() {



//download  categories
var categoryTitles = {}
var categoryTitlesBackEnd = {}
var categoryTitlesBackEndServer = {}
const [content_data, setData] = useState(null);
useEffect(() => {
  fetch('https://api.tablemate.net/fetchDocCategories.php')
    .then(response => response.json())
    .then(content_data =>{ setData(content_data)
      console.log("Results: "+JSON.stringify(content_data))
      try{
        content_data.forEach(element => {
          var url = "https://admin.tablemate.net/sites/default/files/"+element.imageSrc.replace("public://","")
          if (element.application.includes("Front End Mobile Application")){
            if (!Object.keys(categoryTitles).includes(element.url)){
              categoryTitles[element.uri] = {"title":element.labelText, "url":url}
            }
          }
          if (element.application .includes( "Back End Mobile Application")){
            if (!Object.keys(categoryTitlesBackEnd).includes(element.url)){
              categoryTitlesBackEnd[element.uri] = {"title":element.labelText, "url":url}
            }
          }
          if (element.application .includes( "Back End Server Application")){
            if (!Object.keys(categoryTitlesBackEndServer).includes(element.url)){
              categoryTitlesBackEndServer[element.uri] = {"title":element.labelText, "url":url}

            }
          }}); 
        }catch (ex){
          console.log("Error: "+ex)
        } 
        console.log(JSON.stringify(categoryTitles))
        renderCategories(categoryTitles)
        renderCategoriesBackend(categoryTitlesBackEnd)
        renderCategoriesBackendServer(categoryTitlesBackEndServer)
      })
    .catch(error => console.error('Error fetching data:', error));
}, []);

const [docCategories, setProjectCategories] = useState(false);
const renderCategories =  () => {
  console.log("Reacheed " )
  const listCategoryItems = Object.keys(categoryTitles).map((aCategory,index) => (
          
         <div className="details-button col-4" key={index}>
         <Link to = {aCategory+"?s=f"}>
           <img src={categoryTitles[aCategory].url} alt="Restaurant Check-in" className="button-icon" />
           </Link>
           <div>{categoryTitles[aCategory].title}</div>
         </div>
    ));
    setProjectCategories(listCategoryItems)
  return (
    
    listCategoryItems
  );
return null;
};
const [docBackCategories, setBackCategories] = useState(false);
const renderCategoriesBackend =  () => {
  console.log("Reacheed " )
  const listCategoryItems = Object.keys(categoryTitlesBackEnd).map((aCategory,index) => (
          
         <div className="details-button col-4" key={index} >
         <Link to = {aCategory+"?s=b"}>
           <img src={categoryTitlesBackEnd[aCategory].url} alt="Restaurant Check-in" className="button-icon" />
           </Link>
           <div>{categoryTitlesBackEnd[aCategory].title}</div>
         </div>
    ));
    setBackCategories(listCategoryItems)
  return (
    
    listCategoryItems
  );
return null;
};

const [docBackServerCategories, setBackServerCategories] = useState(false);
const renderCategoriesBackendServer =  () => {
  console.log("Reacheed " )
  const listCategoryItems = Object.keys(categoryTitlesBackEndServer).map((aCategory,index) => (
          
         <div className="details-button col-4" key={index}>
         <Link to = {aCategory+"?s=s"}>
           <img src={categoryTitlesBackEndServer[aCategory].url} alt="Restaurant Check-in" className="button-icon" />
           </Link>
           <div>{categoryTitlesBackEndServer[aCategory].title}</div>
         </div>
    ));
    setBackServerCategories(listCategoryItems)
  return (
    
    listCategoryItems
  );
return null;
};

  return (
    <div className="details-container-page">
       <div>
          <h2 className='detailTitle'>App Sections</h2> 
        </div>
        <h3>Front End Mobile Application</h3>
      <div className="button-grid row">
            {docCategories}
      </div> 
      <hr></hr>
      <h3>Back End Mobile Application</h3>
      <div className="button-grid row">
            {docBackCategories}
      </div>
      <hr></hr>
      <h3>Back End Server Application</h3>
      <div className="button-grid row">
            {docBackServerCategories}
      </div>
    </div>
  );
}

export default Home;
