// src/components/SearchBar.js
import React from 'react';
import './SearchBar.css';
import searchIcon from '../Assets/search-button.png';
import { Link } from 'react-router-dom'
import  { useState } from 'react';
import backIcon from '../Assets/arrow-back.png'
function Contact() {
  // State to hold the input value
  const [query, setQuery] = useState('');

  // Update state on input change
  const handleChange = (event) => {
    setQuery(event.target.value);
  };
  function back (){

  }
  return (
    <div className="details-container-page">
      <div className='details-container-page-title'>
          <Link to="/">
        <button className="back-button" onClick={back}>
          <img src={backIcon} alt="Back buutton" />
        </button>
        </Link>
          <h3 className='detailTitle'>Contact Us</h3>
        </div>
        <div>
         <div>Info@tablemate.net </div>
         <div>+267 76020625 </div>
         <div>PO Box 1697 Abg, Gaborone, Botswana </div>
        </div>
      
    </div>
  );
}

export default Contact;
