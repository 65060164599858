// src/components/Navbar.js
import React, { useState } from 'react';
import './navbar.css';
import Sidebar from './sidebar';
import redDotsIcon from '../Assets/red-dots.svg';
import mascot from '../Assets/logo_web.png'
import noticiationIcon from '../Assets/notification-icon.png'
import { Link } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";
function Navbar() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const app = searchParams.get("app")
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  //if coming from back end server applicaiton hide the header bar

  const [showNavBar, sethowNavBar] = useState(app == "besa" ? false:true);

  return (
    <nav className="navbar" style={{display: showNavBar? "flex":"none"}}>
      <div className=''>
       <button className="icon-button" onClick={toggleSidebar}>
          <img src={redDotsIcon} alt="Red Dots" />
        </button>
        <div style={{"display":"inline-block"}}>
        <Link to="/">
          <h1 className='pageTitle'>Help<br></br>Center</h1>   
          </Link>
        </div>
      </div>
        <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <div className="logo ">
        <Link to="/">
          <img src={mascot} alt="Custom Logo" />
        </Link>
      </div>
    </nav>
    
  );
}

export default Navbar;

