import React from 'react';
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";

import { motion } from "framer-motion";
import backIcon from '../Assets/arrow-back.png'
import './DocCategory.css';
function DocCategory ({code,title}) {
  const location = useLocation();
  const { category} = useParams(); // assuming your route has :category/:project
    console.log("Page Route: " + category )
  function back (){

  }
  const [searchParams, setSearchParams] = useSearchParams();
  const s =searchParams.get("s")

  const divRef = useRef(null);
  const scrollToTop = () => {
    // Accessing the current property of the ref to get the DOM node
    const divElement = divRef.current;
    // Scroll the div to the top
    if (divElement) {
      divElement.scrollTop = 0;
    }
  };

//download  categories
var articles = []
const [content_data, setData] = useState(null);
useEffect(() => {
  console.log('https://api.tablemate.net/fetchDocArticles.php?category='+category+"&s="+s)
  fetch('https://api.tablemate.net/fetchDocArticles.php?category='+category+"&s="+s)
    .then(response => response.json())
    .then(content_data =>{ setData(content_data)
      console.log("Results: "+JSON.stringify(content_data))
      try{
        var count =0
        content_data.forEach(element => {
          
          if (element.status=="1"){
            count += 1;
            articles[element.nid] = element
          }
          });  
        }catch (ex){
          console.log("Error: "+ex)
        } 
        console.log(count+ " - elements")
       
        console.log(JSON.stringify(articles))
        renderCategories(articles)
    })
    .catch(error => console.error('Error fetching data:', error));
}, [location]);

const [docArticles, setdocArticles] = useState(false);
const renderCategories =  (list) => {
  const listDocArticleItems = Object.keys(list).map((anArticle,index) => (
    
          <li className='details-link'>
            <Link to={'kb/'+list[anArticle]["nid"]+"?s="+s} state={{ category:category }}><span>{list[anArticle]["title"]}</span></Link>
            </li>

    ));
    setdocArticles(listDocArticleItems)
  return (
    listDocArticleItems
  );
return null;
};


function capitalizeFirstLetterOfEveryWord(str) {
  return str.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}



  return(
    <div className="RetaurantCheckin">
      <div className="details-container-page">
        <div className='details-container-page-title'>
          <Link to="/">
        <button className="back-button" onClick={back}>
          <img src={backIcon} alt="Back buutton" />
        </button>
        </Link>
          <h3 className='detailTitle'>{capitalizeFirstLetterOfEveryWord(category.replace("-"," "))}</h3>
        </div>
        <ul className="doc-article-list">
          {docArticles}
        </ul>
      </div>
    </div>
  );
}

export default DocCategory